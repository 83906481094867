<!--
 * @Date: 2021-12-20 16:20:13
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\views\feedback.vue
-->
<template>
    <div class="bdy">
       
        <div class="bdy-wk">
            <div class="bdy-left">
                <div
                    @click="changePage('feed')"
                    class="menu-item"
                    :class="[pos=='feed'?'hover':'']"
                >
                    <span>问题反馈</span>
                    <i class="el-icon-arrow-right"></i>
                </div>
                <div
                    @click="changePage('update')"
                    class="menu-item"
                    :class="[pos=='update'?'hover':'']"
                >
                    <span>系统更新</span>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="bdy-right">
                <div v-if="pos=='feed'">
                    <el-alert
                        effect="dark"
                        title="为了解大家对期刊查询系统的想法，希望大家可以在反馈区留下你们的意见和建议，我们会对系统进行不断地优化，以便为大家提供更好的使用体验感。"
                        type="success"
                    ></el-alert>
                    <div style="height:32px"></div>
                    <el-form
                        ref="loginForm"
                        :model="form"
                        label-position="top"
                        label-width="90px"
                        :rules="rules"
                    >
                        <el-form-item label="反馈内容(必填)" prop="content">
                            <el-input
                                type="textarea"
                                placeholder="请输入反馈的内容"
                                v-model="form.content"
                                clearable
                                :autosize="{ minRows: 4, maxRows: 8 }"
                                :maxlength="512"
                                show-word-limit
                            ></el-input>
                        </el-form-item>

                        <el-form-item label="上传反馈图片(非必填)">
                            <el-upload
                            ref="fileupload"
                            :on-success="onsucessFile"
                            :on-remove="onRemove"
                            :limit="5"
                            :headers="{Authorization:'Bearer '+$store.state.token}"
                                action="/sys/uploadImg"
                                list-type="picture-card"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>

                        <el-form-item>
                            <el-button @click="onSubmit" type="primary" style="width:237px">确认提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-if="pos=='update'">
                    <div style="padding:24px;background:white">
                        <el-collapse v-if="sysListMsg.length>0" v-model="activeNames">
                            <el-collapse-item v-for="(item,index) in sysListMsg" :key="index" :title="item[0]+'  '+item[1]" :name="index">
                               <div v-html="item[2]"></div>
                            </el-collapse-item>
                           
                        </el-collapse>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'feedback',
    data() {
        return {
            pos: 'feed',
            rules: {
                content: [{ required: true, min: 6, message: '至少，不少于6个字符', trigger: 'change' }]
            },
            form: {
                content: "",
                image: [],
            },
            activeNames: [],

            sysListMsg:[],
        }
    },
    mounted(){
        this.getsystemlist();
    },
    methods: {
        changePage(pos) {
            this.pos = pos;
            if(pos=='update'){
                this.getsystemlist();
            }
        },
        onSubmit() {
            let t = this;
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                   
                     this.$api.putFeedBack(this.form.content,this.form.image).then(d=>{
                        t.$message({ message: '提交成功', type: 'success' })
                        t.$refs.loginForm.resetFields();
                        t.$refs.fileupload.clearFiles();
                        t.$set(this.form,'image',[]);
                    })
                } else {
                   
                    return false;
                }
            });
        },
        
        onRemove(file, fileList){
            let p = [];
            fileList.forEach(element => {
                if(element.status=="success"&&element.response.code==0){
                    p.push(element.response.data)
                }
            });
            
            this.$set(this.form,'image',p);
        },
        onsucessFile(response, file, fileList){
            let p = [];
            fileList.forEach(element => {
                if(element.status=="success"&&element.response.code==0){
                    p.push(element.response.data)
                }
            });
            
            this.$set(this.form,'image',p);
        },
        getsystemlist(){
           
            this.$api.getsystemlist().then(d=>{
                if(d.data.length>0){
                   this.sysListMsg = [...d.data];
                }
            })

        }
    }
}
</script>

<style scoped lang="scss">
.bdy {
    width: 100%;
    .bdy-title{
        text-align: left;
        font-size: 28px;
        max-width: 1250px;
        padding: 0 50px;
        margin: auto;
        margin-top: 50px;
    }
    .bdy-wk {
        max-width: 1250px;
        padding: 0 50px;
        margin: auto;
        display: flex;
        margin-top: 50px;
        .bdy-right {
            background-color: rgb(250, 250, 250);
            padding: 32px 24px;
            width: 100%;
            border-radius: 26px;
            text-align: left;
        }
        .bdy-left {
            width: 220px;
            margin-right: 30px;
            background-color: rgb(250, 250, 250);
            border-radius: 26px;
            padding: 32px 24px;
            .menu-item {
                padding: 12px 24px;
                font-size: 18px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;
                transition: background 0.5s;
                border-radius: 26px;
                &:hover,
                &.hover {
                    background: #01a1c4;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>